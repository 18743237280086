import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import {
  Table
} from '@mementovr/rubix-theme';

import DeleteGuest from './DeleteGuest';

const LIST_EVENT_GUESTS_QUERY = gql`
  query LIST_EVENT_GUESTS_QUERY(
    $eventId: ID!
  ) {
    listEventGuests (
  	  event_id: $eventId
    ) {
      id
      guestRole { id, name }
      user { id name }
      attended
      witnessed
      invite_status
      invite_email
    }
  }
`;




interface IProps {
  eventId: string;
}

const GuestListTable: React.FC<IProps> = ({ eventId }) => {
  const { data, error, loading, refetch } = useQuery(LIST_EVENT_GUESTS_QUERY, { variables: { eventId }});

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const guests = data.listEventGuests;

  return(

    <Table bordered responsive>
      <thead>
        <tr>
          <th>User</th>
          <th>Role</th>
          <th>Invite email</th>
          <th>Status</th>
          <th>Attended</th>
          <th>Witsnesed</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          guests && guests.map( (guest:any) =>
            <Guest key={guest.id}
              id={guest.id}
              name={guest.user ? guest.user.name : '-'}
              inviteEmail={guest.invite_email}
              status={guest.invite_status}
              attended={guest.attended}
              witnessed={guest.witnesed}
              guestRole={guest.guestRole}
              refetch={refetch}
            />
          )
        }


      </tbody>
    </Table>
  );
};

export default GuestListTable;
export {LIST_EVENT_GUESTS_QUERY};

interface IGuestProps {
  id: number,
  name: string;
  inviteEmail: string;
  status: string;
  attended: boolean;
  witnessed: boolean;
  guestRole: any;
  refetch: any;
}

const Guest: React.FC<IGuestProps> = ({id, name, inviteEmail, status, attended, witnessed, guestRole, refetch}) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{guestRole && guestRole.name}</td>
      <td>{inviteEmail}</td>
      <td>{status}</td>
      <td>{ attended ? '+' : '-' }</td>
      <td>{ witnessed ? '+' : '-' }</td>
      <td>
        <DeleteGuest id={id} refetch={refetch} />{' '}
      </td>
    </tr>
  )
}
