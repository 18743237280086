import * as React from 'react';

import {
    SidebarBtn,
    Navbar,
    Grid,
    Row,
    Col
} from '@mementovr/rubix-theme'

import Brand from './Brand';
import HeaderNavigation from './HeaderNavigation';

const Header: React.FC = (props) => {

  return (
    <Grid id='navbar' {...props}>
      <Row>
        <Col xs={12}>
          <Navbar fixedTop fluid id='rubix-nav-header' style={{zIndex: 1}}>
            <Row>
              <Col xs={3} visible='xs'>
                <SidebarBtn onClick={(e:any) => e.preventDefault()}/>
              </Col>
              <Col xs={6} sm={4}>
                <Brand />
              </Col>
              <Col xs={3} sm={8}>
                <HeaderNavigation />
              </Col>
            </Row>
          </Navbar>
        </Col>
      </Row>
    </Grid>
  );
}

export default Header;
