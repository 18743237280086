import * as React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mementovr/rubix-theme';

interface IGalleryNavigationProps {
  eventId: string;
  place?: string;
}

const GalleryNavigation: React.FC<IGalleryNavigationProps> = ({ eventId, place }) => {

  if(!place) {
    return (
      <>
        <Link to={`/event/${eventId}/photo`}><Button>Gallery</Button></Link>{'   '}
        <Link to={`/guestlist/${eventId}`}><Button>Guest list</Button></Link>
      </>
    )
  }

  return (
    <>
      <Link to={`/${place}/${eventId}/photo`}>Photos</Link>{' / '}
      <Link to={`/${place}/${eventId}/panorama`}>Panoramas</Link>{' / '}
      <Link to={`/${place}/${eventId}/video`}>Videos</Link>{' / '}
      <Link to={`/${place}/${eventId}/video360`}>Videos 360</Link>

    </>
  );

};

export default GalleryNavigation;
