import * as React from 'react';
import { Link } from 'react-router-dom';

import { getImageUrl, BucketEnum } from '../../../utils/getImageUrl';

import {
  Grid,
  Row,
  Col,
  Image,
  Button,
  Panel,
  PanelHeader,
  PanelContainer,
}
from '@mementovr/rubix-theme';

interface Props {
  id: number;
  name: string;
  cover: any;
  description: string;
  startAt: Date;
  finishAt: Date;
  address: string;
  isPublic: boolean;
}

const EventCard: React.FC<Props> = ({id, name, cover, description, startAt, finishAt, address, isPublic}) => {

  const edits = {
    "resize": {
      "width": 300,
      "height": 300,
      "fit": "cover"
    }
  };

  const coverUrl = getImageUrl(BucketEnum.ASSETS, cover, edits);

  return (
    <Col xs={6} sm={4} collapseLeft>
      <Link to={`/event/${id}`}>
        <PanelContainer>
          <Panel>
            <PanelHeader>
              <Grid className='gallery-item' style={{maxHeight: '400px'}}>
                <Row>
                  <Col xs={12} style={{padding: 12.5}}>
                    <Image responsive src={coverUrl} alt={name} width='200' height='200'/>
                    <div className='text-center'>
                      <h4 className='fg-darkgrayishblue75 hidden-xs' style={{textTransform: 'none', whiteSpace: 'nowrap'}}>{name}</h4>
                      <h6 className='fg-darkgrayishblue75 visible-xs' style={{textTransform: 'uppercase', whiteSpace: 'nowrap'}}>{name}</h6>

                      <h5 className='fg-darkgray50 hidden-xs' style={{textTransform: 'uppercase'}}>{startAt} - {finishAt}</h5>
                      <h6 className='visible-xs' style={{textTransform: 'uppercase'}}><small className='fg-darkgray50'>{startAt} - {finishAt}</small></h6>

                      <h5 className='fg-darkgray50 hidden-xs' style={{whiteSpace: 'nowrap'}}>{address}</h5>
                      {
                        isPublic ? <Button outlined onlyOnHover bsStyle='red' className='fav-btn'>PUBLIC</Button> :
                        <Button outlined bsStyle='red' className='fav-btn'>PRIVATE</Button>
                      }
                    </div>
                  </Col>
                </Row>
              </Grid>
            </PanelHeader>
          </Panel>
        </PanelContainer>
      </Link>
    </Col>
  );
};

export default EventCard;
