import * as React from 'react';
import { Link } from 'react-router-dom';


export default class ResetPasswordSuccess extends React.PureComponent {

  public render() {

    return (
      <div>
        <h4>Account recovery email sent!</h4>
        <div>If you don’t see this email in your inbox within 15 minutes, look for it in your junk mail folder. If you find it there, please mark it as “Not Junk”.</div>
        <div><Link to='/login'>Login</Link></div>

      </div>
    );
  }
}
