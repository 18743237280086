import gql from 'graphql-tag';

const resetPasswordMutation = gql`
  mutation ResetPasswordMutation($email: String!) {
    forgotPassword (
 		  data: {
        email: $email
    }) {
  	  status
      message
    }
  }
`;

export default resetPasswordMutation;
