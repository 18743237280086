import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Gallery from 'react-grid-gallery';
import { getImageUrl, BucketEnum } from '../../utils/getImageUrl';

// TODO : Refactor this component to use http://neptunian.github.io/react-photo-gallery

import { Grid, Row, Col } from '@mementovr/rubix-theme';
import ImageGalleryControls from './ImageGalleryControls';

interface IImageGalleryProps extends IGalleryPaginationProps {
    images: any;
    onMoveClick: any;
    getSelected: any;
}

const ImageGallery: React.FC<IImageGalleryProps> = ({ images, paginatorInfo, onPageChange, onMoveClick, getSelected }) => {

  const [ photos, setPhotos ] = useState();
  const [selectedCount, setSelectedCount ] = useState(0);


  useEffect(() => {

    // @ts-ignore
    const galleryPhotos = images ? images.map(image=> {

      const thumbnailEdits = {
        "resize": {
          "width": 150,
          "fit": "cover"
        },
        "rotate": image.rotation
      }

      const originalEdits = {
        "rotate": image.rotation
      }

      if(image.rotation == 90) {
        let temp = image.height;
        image.heihgt = image.width;
        image.width = temp;
      }

      return ({
        id: image.id,
        src: getImageUrl(BucketEnum.UPLOADS, image.file.path + '.' + image.file.ext, originalEdits),
        thumbnail: getImageUrl(BucketEnum.UPLOADS, image.file.path + '.' + image.file.ext, thumbnailEdits),
        thumbnailWidth:  image.width,
        thumbnailHeight: image.height
      });
    }
    ) : null;

    setPhotos(galleryPhotos);

  }, [images]);


  const onSelectImage = (index:number) => {

    const imageList = photos.slice();
    const img = imageList[index];

    if(img.hasOwnProperty("isSelected") && img.isSelected) {
      img.isSelected = false;
      setSelectedCount(selectedCount - 1);
    } else {
      img.isSelected = true;
      setSelectedCount(selectedCount + 1);
    }

    setPhotos(imageList);

    getSelected(getSelectedImages(imageList));
  }

  const getSelectedImages = (imageList:any) => {

    return imageList.filter((image:any) => image.isSelected === true).map((image:any) => image.id);
  }


  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <ImageGalleryControls selectedCount={selectedCount} onMoveClick={onMoveClick}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {/* You have to set the witdth of div here because of this issue:
             https://github.com/benhowell/react-grid-gallery/issues/113
          */}

          <div style={{width: '100%'}}>
            <Gallery
              images={photos ? photos : []}
              enableImageSelection={true}
              showLightboxThumbnails={true}
              onSelectImage={onSelectImage}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GalleryPagination
            paginatorInfo={paginatorInfo}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Grid>
  )
}

export default ImageGallery;

interface IGalleryPaginationProps {
  paginatorInfo: any;
  onPageChange: any;
}

const GalleryPagination: React.FC<IGalleryPaginationProps> = ({ paginatorInfo, onPageChange}) => {

  return (
    <div className='text-center'>
      <Pagination
        activePage={paginatorInfo.currentPage}
        itemsCountPerPage={paginatorInfo.perPage}
        totalItemsCount={paginatorInfo.total}
        pageRangeDisplayed={10}
        onChange={onPageChange}
      />
    </div>
  )
}
