import * as React from 'react';
import {
  Form,
  FormGroup,
  InputGroup,
  Icon,
  FormControl,
  Grid,
  Col,
  Row,
  Button
} from "@mementovr/rubix-theme"
import * as Yup from 'yup';

import { FormikProps, FormikErrors, withFormik} from 'formik';
import { UpdateForgottenPasswordMutationVariables } from '../../../../types/schemaTypes';
import { normalizeError } from '../../../../utils/normalizeError';


interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  form: string;
  message: string;
  status: string;
}

interface Props {
  onFinish: () => void;
  submit: (values: UpdateForgottenPasswordMutationVariables) => Promise<FormikErrors<FormValues> | null>;
  token: string;
}

class InnerForm extends React.PureComponent<FormikProps<FormValues> & Props> {

  render() {

    const { handleSubmit, handleChange, touched,  errors } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
      {errors.form &&<div style={{color: "red", marginBottom: 5}}>{errors.form}</div>}
        <FormGroup controlId='emailaddress'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-mail' />
            </InputGroup.Addon>
            <FormControl type='email' name='email' className='border-focus-blue' placeholder='you@example.com' onChange={handleChange} />
          </InputGroup>
          {
            touched.email && errors.email &&
            <div style={{color: "red"}}>{errors.email}</div>}
        </FormGroup>
        <FormGroup controlId='password'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-key' />
            </InputGroup.Addon>
            <FormControl type='password' name='password' className='border-focus-blue' placeholder='Password' onChange={handleChange} />
          </InputGroup>
          {
            touched.password && errors.password
            && <div style={{color: "red"}}>{errors.password}</div>}
        </FormGroup>
        <FormGroup controlId='passwordConfirmation'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-key' />
            </InputGroup.Addon>
            <FormControl type='password' name='passwordConfirmation' className='border-focus-blue' placeholder='Confirm password' onChange={handleChange} />
          </InputGroup>
          {
            touched.passwordConfirmation && errors.passwordConfirmation
            && <div style={{color: "red"}}>{errors.passwordConfirmation}</div>}
        </FormGroup>
        <FormGroup>
          <Grid>
            <Row>
              <Col xs={12} collapseLeft collapseRight>
                <Button type='submit' outlined lg bsStyle='blue' block>Recover account</Button>
              </Col>
            </Row>
          </Grid>
        </FormGroup>
      </Form>
    )
  }
};

const updateForgottenPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),

  password: Yup.string()
    .min(7, 'Password must be at least 8 characters length')
    .required('Please enter your password'),

  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords do not match")
    .min(7, 'Password must be at least 8 characters length')
    .required('Please confirm your password'),
});


export const UpdateForgottenPasswordForm = withFormik<Props, FormValues>({
  validationSchema : updateForgottenPasswordSchema,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({ email: '', password:'', passwordConfirmation:'', message:'', form:'', status:''}),
  handleSubmit: async (values, { props, setErrors}) => {

    await props.submit({
      email: values.email,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      token: props.token
    }).then(

      result => {
        if(result) {
          if(result.status === "PASSWORD_UPDATED") {
            props.onFinish();
            return null;
          }
          setErrors({form: result.message})
        }
        return null;
      }
    )
    .catch(
      e => setErrors({form: normalizeError(e.message)} )
    )

  }
})(InnerForm);
