import * as React from 'react';
import { Query } from 'react-apollo';
import listEventsQuery  from './ListEventsQuery';
import {
    ListEventsQuery,
    ListEventsQuery_listEvents_data,
    ListEventsQueryVariables,
    ListEventsQuery_listEvents_paginatorInfo,
} from '../../types/schemaTypes';

export interface WithListEvents {
    events: ListEventsQuery_listEvents_data[];
    paginatorInfo: ListEventsQuery_listEvents_paginatorInfo;
    loading: boolean;
    loadMore: () => void;
}

interface Props {
    variables: ListEventsQueryVariables
    children: (data: WithListEvents) => JSX.Element | null;
}


const EventListController: React.FC<Props> = (props) => {

  const { children, variables } = props;
  return (
    <Query<ListEventsQuery, ListEventsQueryVariables>
      query={listEventsQuery}
      variables={variables}
    >
      {( {data, loading, fetchMore} ) => {

          let events: ListEventsQuery_listEvents_data[] = [];
          let paginatorInfo: ListEventsQuery_listEvents_paginatorInfo[] | any = {};

          if(data && data.listEvents) {
              events = data.listEvents.data;
          }

          if(data && data.listEvents) {
            paginatorInfo = data.listEvents.paginatorInfo;
          }

          return children({
              events,
              loading,
              paginatorInfo,
              loadMore: () => {
                fetchMore({
                  variables: {
                    ...variables,
                    page: paginatorInfo.currentPage + 1
                  },
                  updateQuery: (prev, {fetchMoreResult}) => {
                    if(!fetchMoreResult) {
                      return prev;
                    } else {
                      // @ts-ignore
                      const newData = fetchMoreResult.listEvents.data;
                      // @ts-ignore
                      const pageInfo = fetchMoreResult.listEvents.paginatorInfo;

                      return pageInfo.hasMorePages ? {

                        listEvents: {
                          // @ts-ignore
                            __typename: prev.listEvents.__typename,
                          // @ts-ignore
                          data: [...prev.listEvents.data, ...newData],
                          paginatorInfo: pageInfo
                        }
                      }  : prev
                    }
                  }

                })
            }
          });
        }
      }
    </Query>
  );
}

export default EventListController;

