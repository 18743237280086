import * as React from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';

import Register from '../components/Register/Register';

import {
  Grid,
  Row,
  Col,
  Panel,
  PanelBody,
  PanelContainer,
  Button,
  Icon
}
from '@mementovr/rubix-theme';

import { Link } from 'react-router-dom';

const RegisterPage: React.FC<RouteComponentProps<{id: string}>> = (props) => {

  const {
    location: { search }
  } = props;

  // parse query params
  const query = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return (
    <div id='auth-container' className='login'>
        <div id='auth-row'>
          <div id='auth-cell'>
            <Grid>
              <Row>
                <Col sm={12}>
                  <PanelContainer controls={false}>
                    <Panel>
                      <PanelBody style={{padding: 0}}>
                        <div className='text-center bg-darkblue fg-white'>
                          <h3 style={{margin: 0, padding: 25}}>Sign up</h3>
                        </div>
                        <div>
                          <div style={{padding: 25, paddingTop: 0, paddingBottom: 0, margin: 'auto', marginBottom: 25, marginTop: 25}}>
                            <Register inviteToken={query.inviteToken} />
                          </div>
                          <div className='bg-hoverblue fg-black50 text-center' style={{padding: 25, paddingTop: 12.5}}>
                            <div style={{marginBottom: 12.5}}>SIGN UP WITH</div>
                            <Grid>
                              <Row>
                                <Col xs={12}>
                                  <Button block type='submit' id='facebook-btn' lg bsStyle='darkblue'>
                                    <Icon glyph='icon-fontello-facebook' />
                                    <span>Facebook</span>
                                  </Button>
                                  <br className='visible-xs' />
                                </Col>
                              </Row>
                            </Grid>
                            <div style={{marginTop: 25}}>
                              Already have an account? <Link to='/login'>Login</Link>
                            </div>
                          </div>
                        </div>
                      </PanelBody>
                    </Panel>
                  </PanelContainer>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
  );
}

export default RegisterPage;
