import gql from 'graphql-tag';

const updateForgottenPasswordMutation = gql`
  mutation UpdateForgottenPasswordMutation($email: String!, $token: String!, $password: String!, $password_confirmation: String!) {
    updateForgottenPassword(
      data: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }) {
        status
        message
      }
  }
`;

export default updateForgottenPasswordMutation;
