import React, {useState} from 'react';

import {
  Grid,
  Row,
  Col,
  Panel,
  PanelBody,
  PanelHeader,
  PanelContainer,
}
from '@mementovr/rubix-theme';

import EventGalleryFilter from './EventGalleryFilter';
import EventGalleryImages from './EventGalleryImages';
import ImageUpload from '../ImageUpload/ImageUpload';

export enum GalleryImageTypes {
  PHOTO = 'PHOTO',
  PANORAMA = 'PANORAMA',
  VIDEO = 'VIDEO',
  VIDEO360 = 'VIDEO360'
}

export enum ImageBelongsTo {
  EVENT = 'EVENT',
  AREA = 'AREA',
  SCENE = 'SCENE',
}

interface IProps {
  id: string;
  galleryType: string;
  place: string;
}

const EventGallery: React.FC<IProps> = ({ place, id, galleryType }) => {

  const [uploaderIds, setUploaderIds] = useState();

  const type = galleryType.toUpperCase();
  const belongsTo = place.toUpperCase();

  // if(!Object.values(GalleryImageTypes).includes(type.toString())) {
  //   return null;
  // }

  // if(!Object.values(ImageBelongsTo).includes(belongsTo)) {
  //   return null;
  // }

  return (
    <PanelContainer controls={false}>
      {
        belongsTo === ImageBelongsTo.EVENT &&
          <ImageUpload
            eventId={id}
            type={type}
            onUploadFinished={() => console.log("uploads finished")}
          />
      }

      <Panel>
        <PanelHeader>
          <Grid>
            <Row>
              <Col xs={12}>
                <EventGalleryFilter
                  imageableId={id}
                  imageableType={belongsTo}
                  type={type}
                  onFilterChange={setUploaderIds}
                />
              </Col>
            </Row>
          </Grid>
        </PanelHeader>
        <PanelBody style={{width: '100vw'}}>
          <Grid>
            <Row>
              <Col xs={12}>
                <h3 className='fg-black50'>Images</h3>
                <Grid>
                  <Row>
                    <Col xs={12} collapseLeft collapseRight>
                      <EventGalleryImages
                        imageableId={id}
                        imageableType={belongsTo}
                        uploaderIds={uploaderIds}
                        type={type}
                        location={belongsTo}
                      />
                      <hr style={{margin: 0}}/>
                    </Col>
                  </Row>
                </Grid>
              </Col>
            </Row>
          </Grid>
        </PanelBody>
      </Panel>
    </PanelContainer>
  )
};

export default EventGallery;
