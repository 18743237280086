import * as React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from '../services/history';
import Layout from '../modules/website/Layout';
import { AuthRoute } from '../modules/auth/AuthRoute';

import { LoginConnector } from '../modules/auth/login/LoginConnector';
// import { RegisterConnector } from '../modules/auth/register/RegisterConnector';
import { UpdateForgottenPasswordConnector } from '../modules/auth/updateForgottenPassword/UpdateForgottenPasswordConnector';
import { ResetPasswordConnector } from '../modules/auth/resetPassword/ResetPasswordConnector';

// Import pages
import EventListPage from '../pages/EventListPage';
import EventDetailsPage from '../pages/EventDetailsPage';
import EventGalleryPage from '../pages/EventGaleryPage';
import EventGuestsPage from '../pages/EventGuestsPage';
import RegisterPage from '../pages/RegisterPage';

// import EventSection from '../components/EventSection';

const Routes: React.FC = () => {

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginConnector}/>
        <Route path="/register" component={RegisterPage} />
        <Route path="/password/reset/:token" component={UpdateForgottenPasswordConnector} />
        <Route path="/password/reset" exact={true} component={ResetPasswordConnector} />

        <AuthRoute path="/" component={() => (
          <Layout>
            <Route exact path="/" render={() => <Redirect to='/events' /> } />
            <AuthRoute exact={true} path='/events' component={EventListPage} />
            <AuthRoute exact={true} path='/event/:id' component={EventDetailsPage} />
            <AuthRoute exact={true} path='/guestlist/:eventId' component={EventGuestsPage} />
            <AuthRoute exact path='/:place/:id/:galleryType' component={EventGalleryPage} />
          </Layout>
        )} />

      </Switch>
    </Router>
  );
}

export default Routes;
