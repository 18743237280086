import * as React from 'react';

import {
  Row,
  Col,
  // Icon,
  Grid,
  Panel,
  // Button,
  PanelBody,
  PanelContainer
}
from '@mementovr/rubix-theme';

import FacebookLoginConnector from '../../facebookLogin/FacebookLoginConnector';

interface LoginViewProps {
  children: JSX.Element;
}

const LoginView: React.FC<LoginViewProps> = ({ children }) => (
  <div id='auth-container' className='login'>
    <div id='auth-row'>
      <div id='auth-cell'>
        <Grid>
          <Row>
            <Col sm={12}>
              <PanelContainer controls={false}>
                <Panel>
                    <PanelBody style={{padding: 0}}>
                      <div className='text-center bg-darkblue fg-white'>
                        <h3 style={{margin: 0, padding: 25}}>Sign in to Memento</h3>
                      </div>
                      <div className='bg-hoverblue fg-black50 text-center' style={{padding: 12.5}}>
                        <div>You need to sign in for those awesome features</div>
                        <div style={{marginTop: 12.5, marginBottom: 12.5}}>
                        <FacebookLoginConnector />
                          {/* <Button id='facebook-btn' lg bsStyle='darkblue' type='submit'>
                            <Icon glyph='icon-fontello-facebook' />
                            <span>Sign in <span className='hidden-xs'>with facebook</span></span>
                          </Button> */}
                        </div>
                      </div>
                      <div>
                        <div className='text-center' style={{padding: 12.5}}>
                          or use your Memento account
                        </div>
                        <div style={{padding: 25, paddingTop: 0, paddingBottom: 0, margin: 'auto', marginBottom: 25, marginTop: 25}}>
                          { children }
                        </div>
                      </div>
                    </PanelBody>
                  </Panel>
                </PanelContainer>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </div>
);

export default LoginView;
