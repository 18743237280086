import * as React from 'react';


import rolling from './assets/rolling.svg';

export const Preloader: React.SFC = () => {

    return (
      <div style={{textAlign: 'center'}}>
        <img src={rolling} alt="Loading..." />
      </div>

    );
}
