import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import {
  ListGroup,
  ListGroupItem
} from '@mementovr/rubix-theme';

import { NavLink } from 'react-router-dom';

const GET_EVENT_NAVIGATION_QUERY = gql`
  query GetEventNavigationQuery(
    $id: ID!
    $location: LocationEnum!
  ) {
    getEvent(id: $id, location: $location) {
      id
      images { id }
		  areas {
        id
        name
        images { id }
        scenes {
          id
          name
          images { id }
        }
      }
    }
  }`;

interface IEventNavigationProps {
  id: string;
  location: string;
}

const EventNavigation: React.FC<IEventNavigationProps> = ({ id, location }) => {
  const { data, error, loading } = useQuery(GET_EVENT_NAVIGATION_QUERY, { variables: { id, location }});

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const event = data.getEvent;


  const areaListItems = event && event.areas && event.areas.map((area:any, index:number) => {
    return (
      <ListGroupItem key={index}>
        <NavLink
          activeStyle={{ color: "#000", fontWeight: "bold"}}
          to={`/area/${area.id}/photo`}
        >Area: {area.name} ({area.images.length})</NavLink>
        <ListGroup>
          {
            area && area.scenes.map((scene:any, sceneIndex:number) => {
              return (
                <ListGroupItem key={sceneIndex}>
                  <NavLink
                    activeStyle={{ color: "#000",fontWeight: "bold" }}
                    to={`/scene/${scene.id}/photo`}
                  >Scene: {scene.name} ({scene.images.length})</NavLink>
                </ListGroupItem>
              )
            })
          }
        </ListGroup>
      </ListGroupItem>
    )
  })

  return (
    <ListGroup>
      {areaListItems}
    </ListGroup>
  )

};

export default EventNavigation;
export { GET_EVENT_NAVIGATION_QUERY };
