import * as React from 'react';

import Select from 'react-select';

import {
  Row,
  Col,
  Grid,
}
from '@mementovr/rubix-theme';
// import { GetSceneQuery_getSceneImageUploaders } from '../../types/schemaTypes';

interface ImageFilterProps {
  uploaderFilterOptions: any,
  onFilterChange: any
 }

 const ImageFilter: React.SFC<ImageFilterProps> = ({ uploaderFilterOptions, onFilterChange }) => {

  const options = Object.keys(uploaderFilterOptions).map((key, val) => ({
    value: uploaderFilterOptions[val].id,
    label: uploaderFilterOptions[val].name
  }));


  return (
    <Row>
      <Col xs={12}>
        <h3 className='fg-black50'>Filter</h3>
        <Grid>
          <Row>
            <Select
              isMulti={true}
              options={options}
              onChange={(values : any) => onFilterChange(values.map((option:any) => option.value))}
              />
          </Row>
        </Grid>
      </Col>
    </Row>
  );
 }

 export default ImageFilter;
