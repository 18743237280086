import * as React from 'react';
import EventList from '../components/EventList/EventList';


const EventListPage: React.FC = () => {
  return (
    <EventList />
  );
}

export default EventListPage;
