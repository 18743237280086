import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import UploadView from './ui/ImageUploadView';
import uploadEventImageMutation from './graphql/UploadEventImage';

interface IDropzoneProps {
  eventId: string;
  type: string;
  onUploadFinished: any;
}

const Dropzone: React.FC<IDropzoneProps> = ({ eventId, type, onUploadFinished }) => {

  const [getPresignedUrl] = useMutation(uploadEventImageMutation);

  const handleDrop =  async (files:any) => {

    const uploaders = files.map( async (file:any) => {

      let extension = file.name.split('.').slice(1).pop() || "";

      const response = await getPresignedUrl(
        { variables: {eventId: eventId, extension: extension, type: type } }
      );

      // @ts-ignore
      const presignedUrl = response.data.uploadEventImage.presignedUrl;


      const contentType = file.type !== '' ? file.type : 'application/octet-stream';


      const res = await axios.put(presignedUrl, file, {
        headers: {'Content-Type': contentType }
      });

      console.log("response", res);
      return res;

    });

    axios.all(uploaders).then(() => {
      console.log("All uploads finished", onUploadFinished);
      onUploadFinished(2);
    })
  }

  const onDrop = useCallback(files => {
    handleDrop(files);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({onDrop});

  const dropzoneStyle = {
    margin : "15px",
    height : "100px",
    border : "3px dashed black",
  };


  return (
    <div>
      <div style={dropzoneStyle}>
        <div {...getRootProps()} style={{height: '100%'}}>
          <input {...getInputProps()} />
          <div style={{
            height: '100%',
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection:"column"}}>
          {
            isDragActive ?
              <p style={{textAlign: "center", fontSize: "20px"}}>Drop the {type} here ...</p> :
              <p style={{textAlign: "center", fontSize: "20px"}}>Drag 'n' drop some {type} here, or click to select files</p>
          }
          </div>
        </div>
      </div>
      <ul>
        {
          acceptedFiles.map(file => (
            <li key={file.name}>{file.name} - {file.size} bytes</li>))
        }
      </ul>
    </div>
  )
}


const ImageUpload: React.FC<IDropzoneProps> = ({ eventId, type, onUploadFinished }) => {

  return (
    <UploadView>
      <Dropzone eventId={eventId} type={type} onUploadFinished={onUploadFinished} />
    </UploadView>
   );
 }

export default ImageUpload;
