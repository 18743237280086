import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
// import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ACCESS_TOKEN } from '../constants';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_PATH
})

// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_GRAPHQL_PATH
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  } as any
})

export default client;
