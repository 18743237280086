import * as React from "react";
import { graphql, ChildProps } from "react-apollo";
import { RouteProps, Route, RouteComponentProps, Redirect } from 'react-router';
import { auth } from '../../services/auth';

import meQuery from './graphql/MeQuery';
import { MeQuery } from '../../types/schemaTypes';


type Props = RouteProps;

export class C extends React.PureComponent<ChildProps<Props, MeQuery>> {

  renderRoute = (routePros: RouteComponentProps) => {
    const { data, component} = this.props;


    if(!auth.isAuthenticated()) {
      return <Redirect to="/login" />;
    }

    if(!data || data.loading) {
      return null;
    }

    if(!data.me) {
      return <Redirect to="/login" />;
    }

    const Component = component as any;

    return <Component {...routePros} />;
  }

  render() {
    const { data: _, component: __, ...rest} = this.props;
    return (<Route {...rest} render={this.renderRoute} />);
  }
}



export const AuthRoute = graphql<Props, MeQuery>(meQuery)(C);
