import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GuestList from '../components/GuestList/GuestList';


const EventGuestsPage: React.FC<RouteComponentProps<{eventId: string}>> = (props) => {

  const {
    match: {
      params: { eventId }
    }
  } = props;

  return (
    <GuestList eventId={eventId} />
  );
}

export default EventGuestsPage;
