import React from 'react';

import {
  Row,
  Col,
  Grid,
  Panel,
  PanelHeader,
  PanelBody,
  PanelContainer,
}
from '@mementovr/rubix-theme';

interface ImageUploadViewProps {
  children: JSX.Element
}

const UploadView: React.SFC<ImageUploadViewProps> = ({children}) => {
  return (
    <Row>
      <Col sm={12}>
        <PanelContainer>
          <Panel>
            <PanelHeader style={{backgroundColor: '#80BAF9'}} className='fg-white'>
              <Grid>
                <Row>
                  <Col xs={12}>
                    <h3 style={{marginBottom: '25px'}}>Contribute images to the event</h3>
                  </Col>
                </Row>
              </Grid>
            </PanelHeader>
            <PanelBody style={{display: 'block'}}>
              { children }
            </PanelBody>
          </Panel>
        </PanelContainer>
      </Col>
    </Row>
  );
}

export default UploadView;
