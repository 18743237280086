import * as React from 'react';
import { UpdateForgottenPasswordController } from './UpdateForgottenPasswordController';
import { RouteComponentProps } from "react-router-dom";

import { UpdateForgottenPasswordForm } from "./ui/UpdateForgottenPasswordForm";
import UpdateForgottenPasswordView from './ui/UpdateForgottenPasswordView';
import UpdateForgottenPasswordSuccess from './ui/UpdateForgottenPasswordSuccess';

export class UpdateForgottenPasswordConnector extends React.PureComponent<
  RouteComponentProps<{
    token: string;
  }>
> {

  state = {
    finished: false
  }

  onFinish = () => {
    this.setState({ finished:true });
  };

  render() {
    const {
      match: {
        params: { token }
      }
    } = this.props;

    return (
      <UpdateForgottenPasswordView>
        {
          this.state.finished ?
            <UpdateForgottenPasswordSuccess /> :

            <UpdateForgottenPasswordController variables={token}>
              {
                ({ submit }) =>
                  <UpdateForgottenPasswordForm token={token} onFinish={this.onFinish} submit={submit} />

              }
            </UpdateForgottenPasswordController>
        }
      </UpdateForgottenPasswordView>
    );
  }
}
