import * as React from 'react';
import { Link } from 'react-router-dom';


export default class UpdateForgottenPasswordSuccess extends React.PureComponent {

  public render() {

    return (
      <div>
        <h5>Your password has been changed successfully!</h5>
        <div>You can now <Link to='/login'>log in</Link></div>

      </div>
    );
  }
}
