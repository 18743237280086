import * as React from 'react';
import { Query } from 'react-apollo';
import meQuery  from '../auth/graphql/MeQuery';

import {
    MeQuery,
    MeQuery_me,

} from '../../types/schemaTypes';

export interface WithMe {
    me: MeQuery_me
    loading: boolean;
}

interface Props {
    children: (data: WithMe) => JSX.Element | null;
}

export class AvatarController extends React.PureComponent<Props> {
    render() {
      const { children } = this.props;
      return (
        <Query<MeQuery>
          query={meQuery}
        >
          {
            ({ data, loading }) => {
              let me: MeQuery_me | any = {};

              if (data && data.me) {
                me = data.me;
              }

              return children({
                me,
                loading
              });
            }
          }
        </Query>
      );
    }
}

