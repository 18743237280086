import * as React from 'react';

import {
  MainContainer,
  Grid,
  Row,
  Col }
from '@mementovr/rubix-theme';

import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';


const Layout: React.FC = props => {

  return (
    <div id="app-container">
      <MainContainer {...props}>
        <Sidebar />
        <Header />
        <div id='body'>
          <Grid>
            <Row>
              <Col xs={12}>
                {props.children}
              </Col>
            </Row>
          </Grid>
        </div>
      </MainContainer>
    </div>
  );
}

export default Layout;
