import * as React from 'react';
import {
  Form,
  FormGroup,
  InputGroup,
  Icon,
  FormControl,
  Grid,
  Col,
  Row,
  Button
} from "@mementovr/rubix-theme"
import * as Yup from 'yup';

import { FormikProps, FormikErrors, withFormik} from 'formik';
import { ResetPasswordMutationVariables } from '../../../../types/schemaTypes';
import { normalizeError } from '../../../../utils/normalizeError';

interface FormValues {
  email: string;
  form: string;
  message: string;
  status: string;
}

interface Props {
  onFinish: () => void;
  submit: (values: ResetPasswordMutationVariables) => Promise<FormikErrors<FormValues> | null>;
}

class InnerForm extends React.PureComponent<FormikProps<FormValues> & Props> {

  render() {

    const { handleSubmit, handleChange, touched,  errors } = this.props;

    return (
      <div>
        <div style={{marginBottom: 10}}>Forgot your account’s password? Enter your email address and we’ll send you a recovery link.</div>
        <Form onSubmit={handleSubmit}>
        {errors.form &&<div style={{color: "red", marginBottom: 5}}>{errors.form}</div>}
          <FormGroup controlId='emailaddress'>
            <InputGroup bsSize='large'>
              <InputGroup.Addon>
                <Icon glyph='icon-fontello-mail' />
              </InputGroup.Addon>
              <FormControl type='email' name='email' className='border-focus-blue' placeholder='you@example.com' onChange={handleChange} />
            </InputGroup>
            {
              touched.email && errors.email &&
              <div style={{color: "red"}}>{errors.email}</div>}
          </FormGroup>

          <FormGroup>
            <Grid>
              <Row>
                <Col xs={12} collapseLeft collapseRight>
                  <Button type='submit' outlined lg bsStyle='blue' block>Send recovery email</Button>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Form>
      </div>
    )
  }
};

const updateForgottenPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
});


export const ResetPasswordForm = withFormik<Props, FormValues>({
  validationSchema : updateForgottenPasswordSchema,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({ email: '', message:'', form:'', status:''}),
  handleSubmit: async (values, { props, setErrors}) => {

    await props.submit({
      email: values.email
    }).then(

      result => {
        if(result) {
          if(result.status === "EMAIL_SENT") {
            props.onFinish();
            return null;
          }
          setErrors({form: result.message})
        }
        return null;
      }
    )
    .catch(
      e => setErrors({form: normalizeError(e.message)} )
    )

  }
})(InnerForm);
