import * as React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Mutation } from 'react-apollo';
import {
  Button,
  Icon
} from '@mementovr/rubix-theme';

import loginWithFacebookMutation from '../graphql/LoginWithFacebookMutation';
import { LoginWithFacebookMutation, LoginWithFacebookMutationVariables } from '../../../types/schemaTypes';
import { auth } from '../../../services/auth';

const FacebookLoginConnector = () => (
  <Mutation<LoginWithFacebookMutation, LoginWithFacebookMutationVariables> mutation={loginWithFacebookMutation}>
    {
      (loginWithFacebook, {error, loading}) => (

        <FacebookLogin
          appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
          render={ (renderProps:any) => (
            <Button id='facebook-btn' lg bsStyle='darkblue' type='submit' onClick={renderProps.onClick}>
              <Icon glyph='icon-fontello-facebook' />
              <span>Sign in <span className='hidden-xs'>with facebook</span></span>
            </Button>
          )}
          callback={async (e:any) => {
            const res = await loginWithFacebook({variables: {accessToken: e.accessToken}});
            if(res && res.data && res.data.loginWithFacebook) {
              auth.login(res.data.loginWithFacebook);
            }
          }}

        />
      )
    }
  </Mutation>
);

export default FacebookLoginConnector;
