import gql from 'graphql-tag';

const listEventsQuery = gql`
  query ListEventsQuery(
      $is_upcomming: Boolean,
      $is_present: Boolean,
      $is_past: Boolean,
      $count: Int!,
      $page: Int
  ) {
    listEvents(
      filter: {
        is_upcomming: $is_upcomming
        is_present: $is_present
        is_past: $is_past
      }
      count: $count
      page: $page
    )
    {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        cover
        description
        start_at
        finish_at
        is_ready
        is_public
        code
        password
        address {
          organisation_name
          address_line_1
          address_line_2
          address_line_3
          town
          postcode
          country {
            name
          }
          latitude
          longitude
        }
      }
    }
}`;


export default listEventsQuery;
