import * as React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useForm from 'react-hook-form';
import {LIST_EVENT_GUESTS_QUERY} from './GuestListTable';

import {
  Modal,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  InputGroup,
  Icon
} from '@mementovr/rubix-theme';


const ADD_EVENT_GUEST_MUTATION = gql`
  mutation ADD_EVENT_GUEST_MUTATION(
    $eventId: ID!,
    $email: String!,
    $guestRole: GuestRoleEnum!

  ) {
    addEventGuest(
      id: $eventId,
      input: {
        event_id: $eventId,
        invite_email: $email,
        guest_role_id: $guestRole
   	  }
    ) {
      id
      event { id }
    }
  }
`;

const SEND_EVENT_INVITE = gql`
  mutation SEND_EVENT_INVITE(
    $guestId: ID!
  ) {
    sendEventInvite(
      input: {
        guest_id: $guestId
      }
    ) {
      id
    }
  }
`;


interface IProps {
  eventId: string,
  isOpen: boolean,
  onCloseClick: any,
  onAddClick: any
}

interface GuestFormData {
  name: string,
  email: string,
  role: string
}


const CreateGuest: React.FC<IProps> = ({ eventId, isOpen, onCloseClick, onAddClick }) => {

  const [addEventGuest] = useMutation(ADD_EVENT_GUEST_MUTATION);
  const [sendEventInvite] = useMutation(SEND_EVENT_INVITE);

  const { register, handleSubmit, errors } = useForm<GuestFormData>();

  const onSubmit = async (data:any) => {
    const response = await addEventGuest({
      variables: { eventId: eventId, email: data.email, guestRole: data.role },
      refetchQueries:[{query: LIST_EVENT_GUESTS_QUERY, variables: { eventId }}]
    });

    if(response.data) {
      // @ts-ignore
      const id = response.data.addEventGuest.id;
      await sendEventInvite({
        variables: { guestId: id},
        refetchQueries:[{query: LIST_EVENT_GUESTS_QUERY, variables: { eventId }}]
      });
    }

    onAddClick();

  };

  return(
    <Modal bsSize="large" show={isOpen}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>Add a new guest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId='emailaddress'>
            <ControlLabel>Guest name</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph='icon-fontello-user' />
              </InputGroup.Addon>
              <input className="form-control" name="name" placeholder="Name" ref={register({ required: true })} />
            </InputGroup>
            {errors.name && <span style={{color: 'red'}}>Please enter guest name</span>}
          </FormGroup>
          <FormGroup controlId='emailaddress'>
            <ControlLabel>Email address</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph='icon-fontello-mail' />
              </InputGroup.Addon>
              <input
                className="form-control"
                name="email"
                type="email"
                placeholder="Please enter guest email"
                ref={register({
                  required: true,
                  pattern: /^(([^<>()\\\\.,;:\s@"]+(\.[^<>()\\\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, }
                  )
                }
              />
            </InputGroup>
            {errors.email && <span style={{color: 'red'}}>Please enter a valid email</span>}
          </FormGroup>
          <FormGroup controlId="dropdownselect">
            <ControlLabel>Guest role</ControlLabel>
            <select className="form-control"  name="role" placeholder="Select role" ref={register}>
              <option value='GUEST'>Guest</option>
              <option value='OWNER'>Owner</option>
              <option value='CURATOR'>Curator</option>
            </select>
          </FormGroup>
        </Modal.Body>
      <Modal.Footer>

        <Button bsStyle='danger' onClick={onCloseClick}>Close</Button>
        <Button type='submit' disabled={false}  bsStyle='primary'>Add Guest</Button>

      </Modal.Footer>
        </Form>
    </Modal>
  );
};


export default CreateGuest;
