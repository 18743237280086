import * as React from 'react';

import {
  Grid,
  Row,
  Col,
  SidebarNav,
  SidebarNavItem
} from '@mementovr/rubix-theme';
import { withRouter } from 'react-router';


// TODO: refactor this component
class Sidebar extends React.Component<any, any> {

// initial state
state = {
  activeItem: this.props.location.pathname
}

public render() {

  const { history }  = this.props;

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <div className='sidebar-nav-container'>
            <SidebarNav style={{marginBottom: 0}}>

              { /** Pages Section */ }
              <div className='sidebar-header' style={{marginLeft: 10}}>PAGES</div>

              {/* <SidebarNavItem
                glyph='icon-fontello-gauge'
                name='Dashboard'
                href={'/dashboard'}
                active={ (this.state.activeItem === '/dashboard') ? true: false }
                onClick={() => {
                  history.push('/dashboard');
                  this.setState({activeItem: '/dashboard'});
                }}
              /> */}
              <SidebarNavItem
                glyph='icon-fontello-calendar'
                name='Events'
                href={'/events'}
                active={ true }
                onClick={() => {
                  history.push('/events');
                  this.setState({activeItem: '/events'});
                }}
              />

              {/* <SidebarNavItem
                glyph='icon-fontello-camera'
                name='Cameras'
                href={'/cameras'}
                active={ this.state.activeItem === '/cameras' ? true: false }
                onClick={() => {
                  history.push('/cameras');
                  this.setState({activeItem: '/cameras'});
                }}
              /> */}

            </SidebarNav>
          </div>
        </Col>
          </Row>
        </Grid>

    );
}
}

const ApplicationSidebar = withRouter(Sidebar);

export default ApplicationSidebar;
