import * as React from 'react';

import {
  Navbar,
} from '@mementovr/rubix-theme'

import logo from './assets/logo_lense_white.svg';

const Brand: React.FC = (props) => {

  return (
    <Navbar.Header {...props}>
      <Navbar.Brand tabIndex='-1'>
        <a href='/'>
          <img src={logo} alt='rubix' width='111' height='28' />
        </a>
      </Navbar.Brand>
    </Navbar.Header>
  );
}

export default Brand;
