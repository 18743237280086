import * as React from 'react';
import {
  graphql,
  withApollo
} from 'react-apollo';
import {
  UpdateForgottenPasswordMutation,
  UpdateForgottenPasswordMutationVariables
} from '../../../types/schemaTypes';

import updateForgottenPasswordMutation from '../graphql/UpdateForgottenPasswordMutation';


interface Props {
  variables: any;
  onSessionId?: (sessionId: string) => void;
  children: (
    data: {
      submit: (
        values: UpdateForgottenPasswordMutationVariables
      ) => Promise<any>;
    }
  ) => JSX.Element | null;
}

class C extends React.PureComponent
{
  submit = async (values: UpdateForgottenPasswordMutationVariables) => {
    // @ts-ignore
    const response = await this.props.mutate({
      variables: values
    });

    if(response && response.errors) {
      return response.errors;
    }

    if(response && response.data && response.data.updateForgottenPassword) {
      return response.data.updateForgottenPassword;
    }

    return null;
  };

  render() {
    // @ts-ignore
    return this.props.children({ submit: this.submit });
  }
}

export const UpdateForgottenPasswordController = graphql<
  Props,
  UpdateForgottenPasswordMutation,
  UpdateForgottenPasswordMutationVariables
>(updateForgottenPasswordMutation)(withApollo<Props>(C as any));
