import * as React from 'react';
import { LoginController } from './LoginController';
import { RouteComponentProps } from "react-router-dom";

import { LoginForm } from "./ui/LoginForm";
import LoginView from './ui/LoginView';

export class LoginConnector extends React.PureComponent<RouteComponentProps<{}>> {

  onFinish = () => {
    console.log("finished");
  };

  render() {
    return (
      <LoginView>
        <LoginController>
          {({ submit }) => <LoginForm onFinish={this.onFinish} submit={submit} />}
        </LoginController>
      </LoginView>
    );
  }
}
