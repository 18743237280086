import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import EventDetails from '../components/EventDetails/EventDetails';

import {
  Row,
  Col,
  Panel,
  PanelLeft,
  PanelContainer,
}
from '@mementovr/rubix-theme';

import GalleryNavigation from '../components/GalleryNavigation/GalleryNavigation';

const EventListPage: React.FC<RouteComponentProps<{id: string}>> = (props) => {

  const {
    match: {
      params: { id }
    }
  } = props;

  return (
    <PanelContainer plain collapseBottom controls={false}>
        <Panel horizontal>
          <PanelLeft>
            <Row>
              <Col xs={12}>
                <EventDetails compact={false} eventId={id}>
                  <GalleryNavigation eventId={id} />
                </EventDetails>

              </Col>
            </Row>
          </PanelLeft>
        </Panel>
      </PanelContainer>
  );
}

export default EventListPage;
