import * as React from 'react';
import { AvatarController } from './AvatarController';

import AvatarView  from './ui/AvatarView';

import { MeQuery_me } from '../../types/schemaTypes';


export class AvatarConnector extends React.PureComponent {
  render() {

    return (
      <AvatarController>

          {data => {

            if (!data.me ) {
              return <div>...loading</div>;
            }
            const e: MeQuery_me | any = data.me;

            return (
              <AvatarView me={e} />

            );
          }}
      </AvatarController>
    );
  }
}
