import gql from 'graphql-tag';

const uploadEventImageMutation = gql`
    mutation UploadEventImageMutation(
        $eventId: Int!,
        $extension: String!
        $type: EventImageType!
    ) {
      uploadEventImage(
        input: {
          event_id: $eventId
          image_type: $type
          extension: $extension
      }) {
      presignedUrl
    }
}`;

export default uploadEventImageMutation;
