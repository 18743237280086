import * as React from 'react';
import {
  Form,
  FormGroup,
  InputGroup,
  Icon,
  FormControl,
  Grid,
  Col,
  Row,
  Button
} from "@mementovr/rubix-theme"
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { FormikProps, withFormik} from 'formik';
import { LoginMutationVariables } from '../../../../types/schemaTypes';
import { normalizeError } from '../../../../utils/normalizeError';

interface FormValues {
  username: string;
  password: string;
  form: string;
}

interface Props {
  onFinish: () => void;
  submit: (values: LoginMutationVariables) => Promise<any>;
}

class InnerForm extends React.PureComponent<FormikProps<FormValues> & Props> {

  render() {
    const { handleSubmit, handleChange, touched,  errors } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
      {errors.form &&<div style={{color: "red", marginBottom: 5}}>{errors.form}</div>}
        <FormGroup controlId='username'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-mail' />
            </InputGroup.Addon>
            <FormControl autoFocus name='username' type='text' className='border-focus-blue' placeholder='you@example.com' onChange={handleChange} />
          </InputGroup>
          {
            touched.username && errors.username &&
            <div style={{color: "red"}}>{errors.username}</div>}
        </FormGroup>

        <FormGroup controlId='password'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-key' />
            </InputGroup.Addon>
            <FormControl name='password' type='password' className='border-focus-blue' placeholder='password' onChange={handleChange} />
          </InputGroup>
          {
            touched.password && errors.password
            && <div style={{color: "red"}}>{errors.password}</div>}
        </FormGroup>

        <FormGroup>
          <Grid>
            <Row>
              <Col xs={6} collapseLeft collapseRight style={{paddingTop: 10}}>
                <div>
                  <Link to={"/register"}>Create a Memento account</Link>
                </div>
                <div>
                  <Link to={"/password/reset"}>Forgot password?</Link>
                </div>
              </Col>
              <Col xs={6} collapseLeft collapseRight className='text-right'>
                <Button outlined lg type='submit' bsStyle='blue'>Login</Button>
              </Col>
            </Row>
          </Grid>
        </FormGroup>
    </Form>
    )
  }
};

const loginSchema = Yup.object().shape({
  password: Yup.string()
    // .min(6, 'Password must be at least 6 characters')
    .required('Please enter your password'),

  username: Yup.string()
    .required('Please enter your email'),
});

export const LoginForm = withFormik<Props, FormValues>({
  validationSchema : loginSchema,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({ username: '', password:'', form:'' }),
  handleSubmit: async (values, { props, setErrors}) => {

    await props.submit({
      username: values.username,
      password: values.password
    })
    .then(
      result => {
        if(result) {
          setErrors({form: result[0].message})
        }
      }
    )
    .catch(
      e => setErrors({form: normalizeError(e.message)} )
    )

  }
})(InnerForm);
