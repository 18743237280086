import gql from 'graphql-tag';

const loginMutation = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(data: {
      username: $username,
      password: $password
    }) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export default loginMutation;
