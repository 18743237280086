import * as React from 'react';

import {
  Row,
  Col,
  Grid,
  Panel,
  Icon,
  PanelHeader,
  PanelBody,
  PanelContainer,
} from '@mementovr/rubix-theme';

import { getImageUrl, BucketEnum } from '../../../utils/getImageUrl';

interface IProps {
  id: number;
  isCompact: boolean;
  cover: string;
  name: string;
  startAt: string;
  finishAt: string;
  description: string;
  children: JSX.Element;
}


const EventView: React.FC<IProps> = ({ id, cover, name, startAt, finishAt, description, isCompact, children }) => {

  const edits = {
    "resize": {
      "width": 600,
      "height": 400,
      "fit": "cover"
    }
  };

  const coverUrl = getImageUrl(BucketEnum.ASSETS, cover, edits);

  return (
    <PanelContainer controls={false}>
      <Panel>
        {isCompact !== true ?
        <PanelHeader>
          <div style={
            {
              backgroundImage: 'url(' + coverUrl + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              overflow: 'hidden',
              height: 450,
            }}
          />
        </PanelHeader> : null }
        <PanelBody style={{paddingBottom: 25}}>
          <Grid>
            <Row>
              <Col xs={12}>
                <h3 className='fg-black50'>{name}</h3>
                <Grid>
                  <Row>
                    <Col xs={12} collapseLeft collapseRight>
                      <div className='fg-black50'>
                        <small><Icon glyph='icon-ikons-time' style={{position: 'relative', top: 1}} />  {startAt} - {finishAt}</small>
                      </div>
                    </Col>
                    <Col xs={12} collapseLeft collapseRight>
                      <div className='fg-black50'>
                        <small>{description}</small>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Col>
            </Row>

            <Row>
              <Col xs={12} style={{textAlign: 'center', marginTop: 15}}>
                { children }
              </Col>
            </Row>
          </Grid>

        </PanelBody>
      </Panel>
    </PanelContainer>
  )
};

export default EventView;
