import gql from 'graphql-tag';

const meQuery = gql`
  query MeQuery {
    me {
      id
      name
      avatar
    }
  }
`;

export default meQuery;
