import React from 'react';
import {useMutation} from 'react-apollo-hooks';
import gql from 'graphql-tag';
import useForm from 'react-hook-form';
import { auth } from '../../services/auth';

import {
  Form,
  FormGroup,
  InputGroup,
  Icon,
  Grid,
  Row,
  Col,
  Button
} from '@mementovr/rubix-theme';


const REGISTER_MUTATION = gql`
  mutation REGISTER_MUTATION(
    $name: String!,
    $email: String!,
    $password: String!
    $inviteToken: String
  ) {
    register(
      input: {
        name: $name,
        email: $email,
        password: $password
        inviteToken: $inviteToken
      }
    ) {
      ok
      token_type
      expires_in
      access_token
      refresh_token
    }
  }
`;

interface IProps {
  inviteToken?: string;
}

interface RegisterFormData {
  name: string,
  email: string,
  password: string
}


const Register: React.FC<IProps> = ({ inviteToken }) => {

  const registerUser = useMutation(REGISTER_MUTATION);

  const { register, handleSubmit, errors } = useForm<RegisterFormData>();


  const onSubmit = async (submitData:any) => {

    // @ts-ignore
    const response = await registerUser({
      variables: {name: submitData.name, email: submitData.email, password: submitData.password, inviteToken: inviteToken }
    });

    if(response) {
      if(response.data) {
        // @ts-ignore
        if(response.data.register.ok) {
          // @ts-ignore
          auth.login(response.data.register);

        }
      }
    }

  };

  return (
      <Form onSubmit={handleSubmit(onSubmit)}>

        <FormGroup controlId='name'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-user' />
            </InputGroup.Addon>
            <input className="form-control" name="name" placeholder="Your Name" ref={register({ required: true })} />
          </InputGroup>
          {errors.name && <span style={{color: 'red'}}>Please enter your name</span>}
        </FormGroup>

        <FormGroup controlId='emailaddress'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-mail' />
            </InputGroup.Addon>
            <input
                className="form-control"
                name="email"
                type="email"
                placeholder="Your Email"
                ref={register({
                  required: true,
                  // @ts-nocheck
                  pattern: /^(([^<>()\\\\.,;:\s@"]+(\.[^<>()\\\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, }
                  )
                }
              />
          </InputGroup>
          {errors.email && <span style={{color: 'red'}}>Please enter a valid email</span>}
        </FormGroup>

        <FormGroup controlId='password'>
          <InputGroup bsSize='large'>
            <InputGroup.Addon>
              <Icon glyph='icon-fontello-key' />
            </InputGroup.Addon>
            <input className="form-control" name="password" type="password" placeholder="Your Password" ref={register({ minLength: 8 })} />
          </InputGroup>
          {errors.password && <span style={{color: 'red'}}>Please enter a valid password ( at least 8 characters ) </span>}
        </FormGroup>

        <FormGroup>
          <Grid>
            <Row>
              <Col xs={12} collapseLeft collapseRight>
                <Button type='submit' outlined lg bsStyle='blue' block>Create account</Button>
              </Col>
            </Row>
          </Grid>
        </FormGroup>
      </Form>
    );

};

export default Register;

