import gql from 'graphql-tag';

const loginWithFacebookMutation = gql`
  mutation LoginWithFacebookMutation($accessToken: String!) {
    loginWithFacebook(input: {
      access_token: $accessToken
    }) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export default loginWithFacebookMutation;
