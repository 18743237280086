import * as React from 'react';
import {
  graphql,
  withApollo
} from 'react-apollo';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables
} from '../../../types/schemaTypes';

import resetPasswordMutation from '../graphql/ResetPasswordMutation';


interface Props {
  onSessionId?: (sessionId: string) => void;
  children: (
    data: {
      submit: (
        values: ResetPasswordMutationVariables
      ) => Promise<any>;
    }
  ) => JSX.Element | null;
}

class C extends React.PureComponent
{
  submit = async (values: ResetPasswordMutationVariables) => {
    // @ts-ignore
    const response = await this.props.mutate({
      variables: values
    });

    if(response && response.errors) {
      return response.errors;
    }

    if(response && response.data && response.data.forgotPassword) {
      return response.data.forgotPassword;
    }

    return null;
  };

  render() {
    // @ts-ignore
    return this.props.children({ submit: this.submit });
  }
}

export const ResetPasswordController = graphql<
  Props,
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>(resetPasswordMutation)(withApollo<Props>(C as any));
