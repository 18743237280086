import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import SceneView from './ui/SceneView';

const GET_SCENE_QUERY = gql`
  query GetSceneQuery(
    $id: Int!
  ) {
      getScene(id: $id) {
        id
        name
        cover
      }
    }
  `;

interface IProps {
  id: number;
  compact: boolean;
  children: JSX.Element;
}

const SceneDetails: React.FC<IProps> = ({ id, compact, children }) => {
  const { data, error, loading } = useQuery(GET_SCENE_QUERY, { variables: { id }});

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const scene = data.getScene;

  return(
    <SceneView
      id={scene.id}
      name={scene.name}
      cover={scene.cover}
      isCompact={compact}
    >
      { children }
    </SceneView>
  );

};

export default SceneDetails;
