import React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import ImageFilter from '../ImageFilter/ImageFilter';

const GET_IMAGE_UPLOADERS_QUERY = gql`
    query GET_IMAGE_UPLOADERS_QUERY(
      $imageableId: Int!
      $imageableType: ImageLocation!
      $type: EventImageType!
    ) {
      getImageUploaders(
        imageableId: $imageableId,
        imageableType: $imageableType,
        type: $type,
      ) {
        id
        name
      }
    }
`;

interface IProps {
  imageableId: string;
  imageableType: string;
  type?: string;
  onFilterChange: any;
}

const EventGalleryFilter: React.FC<IProps> = ({ imageableId, imageableType, type, onFilterChange}) => {

  const { data, error, loading } = useQuery(
    GET_IMAGE_UPLOADERS_QUERY, {
      variables: { imageableId, imageableType, type } }
  );

  if(loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const uploaderFilterOptions = data.getImageUploaders;

  if(uploaderFilterOptions && uploaderFilterOptions.length > 0) {
    return (
      <ImageFilter
        uploaderFilterOptions={uploaderFilterOptions}
        onFilterChange={onFilterChange}
      />
    )
  }

  return null;
};

export default EventGalleryFilter;
