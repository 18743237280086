import * as React from 'react';
import {
  graphql,
  withApollo,
} from 'react-apollo';

import { LoginMutation, LoginMutationVariables } from '../../../types/schemaTypes';
import loginMutation from '../graphql/LoginMutation';
import { auth } from '../../../services/auth';

interface Props {
  onSessionId?: (sessionId: string) => void;
  children: (
    data: {
      submit: (
        values: LoginMutationVariables
      ) => Promise<any>;
    }
  ) => JSX.Element | null;
}

class C extends React.PureComponent
{
  // @ts-ignore
  submit = async (values: LoginMutationVariables) => {
    // @ts-ignore
    const response = await this.props.mutate({
      variables: values
    });

    if(response && response.errors) {
      return response.errors;
    }

    if(response && response.data) {
      auth.login(response.data.login);
    }

    return null;
  };

  render() {
    // @ts-ignore
    return this.props.children({ submit: this.submit });
  }
}


export const LoginController = graphql<
  Props,
  LoginMutation,
  LoginMutationVariables
>(loginMutation)(withApollo<Props>(C as any));
