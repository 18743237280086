import gql from 'graphql-tag';

const refreshMutation = gql`
  mutation RefreshMutation($refresh_token: String!) {
    refreshToken(
      data: {
        refresh_token: $refresh_token
      }
    ) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export default refreshMutation;
