import * as React from 'react';

import {
  Row,
  Col,
  Grid,
}
from '@mementovr/rubix-theme';
import { MeQuery_me } from '../../../types/schemaTypes';


interface Props {
  me: MeQuery_me
}

export default class AvatarView extends React.PureComponent<Props> {

  render() {
    const { name } = this.props.me;

    return (
      <div id='avatar'>
        <Grid>
          <Row className='gh-white'>
            <Col xs={1} collapseRight>
              {/* <img src={avatar || undefined } width='40' height='40' /> */}
            </Col>
            <Col xs={8} collapseLeft id='avatar-col'>
              <div style={{top: 30, fontSize: 16, lineHeight: 1, position: 'relative', color: '#fff'}}>{ name }</div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
