import * as React from 'react';

import ApplicationSidebar from './ApplicationSidebar';
import { AvatarConnector } from '../../../modules/avatar/AvatarConnector';

const SidebarContainer: React.FC = () => {
  return (
    <div id='sidebar'>
      <AvatarConnector />
      <div id='sidebar-container'>
        <ApplicationSidebar />
      </div>
    </div>
  )
}

export default SidebarContainer;



