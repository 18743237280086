import * as React from 'react';

import {
  Row,
  Col,
  Grid,
  Panel,
  PanelHeader,
  PanelBody,
  PanelContainer,
} from '@mementovr/rubix-theme';


interface IProps {
  id: number;
  isCompact: boolean;
  cover: string;
  name: string;
  children: JSX.Element;
}


const AreaView: React.FC<IProps> = ({ id, cover, name, isCompact, children }) => (
  <PanelContainer controls={false}>
        <Panel>
          {isCompact !== true ?
          <PanelHeader>
            <div style={
              {
                backgroundImage: 'url(' + cover + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                overflow: 'hidden',
                height: 450,
              }}
            />
          </PanelHeader> : null }
          <PanelBody style={{paddingBottom: 25}}>
            <Grid>
              <Row>
                <Col xs={12}>
                  <h3 className='fg-black50'>{name}</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={{textAlign: 'center', marginTop: 15}}>
                  { children }
                </Col>
              </Row>
            </Grid>

          </PanelBody>
        </Panel>
      </PanelContainer>
);

export default AreaView;
