import * as React from 'react';
import { ResetPasswordController } from './ResetPasswordController';

import { ResetPasswordForm } from "./ui/ResetPasswordForm";
import ResetPasswordView from './ui/ResetPasswordView';
import ResetPasswordSuccess from './ui/ResetPasswordSuccess';

export class ResetPasswordConnector extends React.PureComponent{

  state = {
    finished: false
  }

  onFinish = () => {
    this.setState({ finished:true });
  };

  render() {

    return (
      <ResetPasswordView>
        {
          this.state.finished ?
            <ResetPasswordSuccess /> :

            <ResetPasswordController>
              {
                ({ submit }) =>
                  <ResetPasswordForm onFinish={this.onFinish} submit={submit} />

              }
            </ResetPasswordController>
        }
      </ResetPasswordView>
    );
  }
}
