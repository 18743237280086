import * as React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import {
  Button,
} from '@mementovr/rubix-theme';


const DELETE_EVENT_GUEST_MUTATION = gql`
  mutation DELETE_EVENT_GUEST_MUTATION(
    $id: ID!
  ) {
    deleteEventGuest(
      id: $id
    ) {
      id
    }
  }
`;



interface IProps {
  id: number,
  refetch: any
}

const DeleteGuest: React.FC<IProps> = ({ id, refetch }) => {

  const [deleteEventGuest] = useMutation(DELETE_EVENT_GUEST_MUTATION);

  return(
    <Button onClick={() => {

      if(window.confirm('Are you sure? ')) {
        deleteEventGuest({
          variables: { id }
        }).then(
          refetch()
        ).catch(err => {
          alert(err.message);
        });
      }
    }}>DELETE</Button>
  );
};


export default DeleteGuest;
