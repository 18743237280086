import { ACCESS_TOKEN, REFRESH_TOKEN, EXPIRES_AT } from '../constants';
import history from './history';
import client from '../services/apolloClient';
import refreshMutation  from '../modules/auth/graphql/RefreshMutation';

export const auth = {

  login(authResult:any) {
    const expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
    localStorage.setItem(ACCESS_TOKEN, authResult.access_token);
    localStorage.setItem(REFRESH_TOKEN, authResult.refresh_token);
    localStorage.setItem(EXPIRES_AT, expiresAt);
    history.push('/');
  },


  update(authResult:any) {
    if (authResult.refreshToken) {
      const expiresAt = JSON.stringify((authResult.refreshToken.expires_in * 1000) + new Date().getTime());
      localStorage.setItem(ACCESS_TOKEN, authResult.refreshToken.access_token);
      localStorage.setItem(REFRESH_TOKEN, authResult.refreshToken.refresh_token);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      return true;
    }
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_AT);

    history.push('/login');
    return false;
  },

  isAuthenticated()  {

    const expiresAt = JSON.parse(JSON.stringify(localStorage.getItem('expires_at')));
    const isTokenValid = new Date().getTime() < expiresAt;
    if (isTokenValid === true) {
      return true;
    }

    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (refreshToken) {
      return this.refresh(refreshToken);
    }

    return false;
  },

  refresh(refreshToken:string) : Promise<boolean> {
    console.log("refresh");
    return new Promise((resolve, reject) =>{

      client.mutate(
        {
          errorPolicy: 'ignore',
          mutation: refreshMutation,
          variables : {
            refresh_token: refreshToken
          }
        }
      ).then(result => {
        resolve(this.update(result.data));
      })
    })
  },

  logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    history.push('/login');
  }
}
