import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import EventListController from './EventListController';
import EventCard from './ui/EventCard';

import { Preloader } from '../Preloader/Preloader';

const EventList: React.FC = () => {

  return (
    <EventListController
      variables={
        {
          count: 18,
          is_present: true,
          is_past: true,
          is_upcomming:true
        }
      }
    >
      {({events, loadMore, paginatorInfo}) => {

          if(!events) {
              return <div>Loading...</div>
          }
          return (
              <InfiniteScroll
                  loadMore={loadMore}
                  hasMore={paginatorInfo.hasMorePages}
                  loader={<Preloader key={0} />}
              >
              <div>
                {events.map((e:any, k) => (
                  <EventCard key={k}
                    id={e.id}
                    address={e.address.address_line_1 + ', ' + e.address.town + ' ' + e.address.postcode + ', ' + e.address.country.name }
                    name={e.name}
                    description={e.description}
                    cover={e.cover}
                    startAt={e.start_at}
                    finishAt={e.finish_at}
                    isPublic={e.is_public}

                  />
                  ))}
              </div>
              </InfiniteScroll>
          )
      }}
    </EventListController>
  );
}

export default EventList;


