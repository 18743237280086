export enum BucketEnum {
  ASSETS = 'ASSETS',
  UPLOADS = 'UPLOADS'
};

export const getImageUrl = (bucket: BucketEnum, key:string, edits:object) => {

  const imageRequest = JSON.stringify({
    bucket: bucket === BucketEnum.ASSETS ? process.env.REACT_APP_ASSETS_BUCKET : process.env.REACT_APP_UPLOADS_BUCKET,
    key: key,
    edits: edits
  });

  const imageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/${btoa(imageRequest)}`;

  return imageUrl;
};
