import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Grid,
  Row,
  Col,
  Panel,
  PanelLeft,
  PanelRight,
  PanelContainer,
}
from '@mementovr/rubix-theme';

import EventGallery from '../components/EventGallery/EventGallery';
import EventNavigation from '../components/EventNavigation/EventNavigation';
import GalleryNavigation from '../components/GalleryNavigation/GalleryNavigation';
import AreaDetails from '../components/AreaDetails/AreaDetails';
import SceneDetails from '../components/SceneDetails/SceneDetails';
import EventDetails from '../components/EventDetails/EventDetails';


// This is how we can get dimensions from url
/*

function getMeta(url, callback) {
   const img = new Image();
   img.onload = () => callback(img.width, img.height);
   img.src = url;
}

getMeta("http://snook.ca/files/mootools_83_snookca.png", (width, height) => alert(width + 'px ' + height + 'px'));

*/

const EventGaleryPage: React.FC<RouteComponentProps<{
  place: string,
  id: string,
  galleryType: string }>> = (props) => {

  const {
    match: {
      params: { place, id, galleryType }
    }
  } = props;

  return (
    <PanelContainer plain collapseBottom controls={false}>
      <Panel horizontal>
        <PanelLeft>
          <EventGallery id={id} galleryType={galleryType} place={place} />
        </PanelLeft>
        <PanelRight className='hidden-xs' style={{width: 350}}>
          <Grid>
            <Row>
              <Col xs={12} collapseRight>
                { showEventDetails(place, id) }
              </Col>
            </Row>
            <Row>
              <Col xs={12} collapseRight>
                { showEventNavigation(id, place) }
              </Col>
            </Row>
          </Grid>
        </PanelRight>
      </Panel>
    </PanelContainer>
  );
}

const showEventNavigation  = ( id:string, location:string ) => {
  return (
    <EventNavigation id={id} location={location.toUpperCase()} />
  );
}

const showEventDetails = ( place:any, id:any ) => {

  switch (place) {
    case 'area':
      return (
        <AreaDetails compact={true} id={id}>
          <GalleryNavigation eventId={id} place={place} />
        </AreaDetails>
      );
    case 'scene':
      return (
        <SceneDetails compact={true} id={id}>
          <GalleryNavigation eventId={id} place={place} />
        </SceneDetails>
      );
    default:
      return (
        <EventDetails compact={true} eventId={id}>
          <GalleryNavigation eventId={id} place={place} />
        </EventDetails>
      );
  }
}

export default EventGaleryPage;
