import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import EventView from './ui/EventView';

const GET_EVENT_QUERY = gql`
  query GetEventQuery(
    $id: ID!
    $location: LocationEnum!
  ) {
    getEvent(id: $id, location: $location) {
      id
      creator {
        id
        name
        avatar
      }
      name
      cover
      description
      start_at
      finish_at
      is_ready
      code
      password
      address {
        organisation_name
        address_line_1
        address_line_2
        address_line_3
        town
        postcode
        country {
          name
        }
        latitude
        longitude
      }
      areas {
        id
        name
        scenes {
          id
          name
        }
      }
    }
  }`;

interface IProps {
  eventId: string;
  compact: boolean;
  children: JSX.Element;
}

const EventDetails: React.FC<IProps> = ({ eventId, compact, children }) => {
  const { data, error, loading } = useQuery(GET_EVENT_QUERY, { variables: { id: eventId, location: 'EVENT' }});

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const event = data.getEvent;

  return(
    <EventView
      id={event.id}
      isCompact={compact}
      name={event.name}
      cover={event.cover}
      startAt={event.start_at}
      finishAt={event.finish_at}
      description={event.description}
    >
      { children }
    </EventView>
  );

};


export default EventDetails;
