import React, {useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import ImageGallery from '../ImageGallery/ImageGallery';
import EventGalleryMoveDialog from './EventGalleryMoveDialog';

const IMAGE_COUNT_PER_PAGE = 50;

const LIST_IMAGES_QUERY = gql`
  query LIST_IMAGES(
    $imageableId: Int!,
    $imageableType: ImageLocation!
    $uploaderIds: [Int!],
    $type: EventImageType!,
    $count: Int!
    $page: Int!
  ) {
    listImages(
      imageableId: $imageableId
      imageableType: $imageableType
      uploaderIds: $uploaderIds
      type: $type
      count: $count
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        width
        height
        rotation
        file {
          path
          ext
          ogExt
          uploader { id, name }
        }
      }
    }
  }`;

interface IProps {
  imageableId: string;
  imageableType: string;
  uploaderIds: any;
  type?: string;
  location: string;
}

const EventGalleryImages: React.FC<IProps> = ({ imageableId, imageableType, uploaderIds, type, location }) => {

  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const { data, error, loading, refetch } = useQuery(
    LIST_IMAGES_QUERY, {
      variables: { imageableId, imageableType, uploaderIds, type, page, count: IMAGE_COUNT_PER_PAGE },
      pollInterval: 500
    }
  );

  const handleClose = () => {
    setModalOpen(false);
  }
  const handleCloseWithRefetch = () => {
    setModalOpen(false);
    refetch();
  }

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const images = data.listImages.data;
  const paginatorInfo = data.listImages.paginatorInfo;

  if(images && images.length > 0) {
    return (
      <>
        <ImageGallery
          images={images}
          paginatorInfo={paginatorInfo}
          onPageChange={(pageNumber:number) => setPage(pageNumber)}
          onMoveClick={() => setModalOpen(true)}
          getSelected={(selection:any) => setSelectedImages(selection)}
        />
        <EventGalleryMoveDialog
          id={imageableId}
          location={location}
          isOpen={modalOpen}
          selectedImages={selectedImages}
          onCloseClick={ () => handleClose()  }
          onMoveClick={() =>  handleCloseWithRefetch() }
        />
      </>
    )
  }

  return <h3>Aww there is nothing to display :(</h3>

};

export default EventGalleryImages;
export { LIST_IMAGES_QUERY };
