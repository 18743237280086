import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import AreaView from './ui/AreaView';

const GET_AREA_QUERY = gql`
  query GetAreaQuery(
    $id: Int!
  ) {
      getArea(id: $id) {
        id
        name
        cover

        address {
          organisation_name
          address_line_1
          address_line_2
          address_line_3
          town
          postcode
          country {
            name
          }
          latitude
          longitude
        }
      }
    }
  `;

interface IProps {
  id: number;
  compact: boolean;
  children: JSX.Element;
}

const AreaDetails: React.FC<IProps> = ({ id, compact, children }) => {
  const { data, error, loading } = useQuery(GET_AREA_QUERY, { variables: { id }});

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const area = data.getArea;

  return(
    <AreaView
      id={area.id}
      name={area.name}
      cover={area.cover}
      isCompact={compact}
    >
      { children }
    </AreaView>
  );

};

export default AreaDetails;
