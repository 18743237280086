import React, {useState} from 'react';

import {
  Grid,
  Row,
  Col,
  Button,
  Panel,
  PanelHeader,
  PanelBody,
  // PanelLeft,
  PanelContainer,
}
from '@mementovr/rubix-theme';

import GuestListTable from './GuestListTable';
import CreateGuest from './CreateGuest';


interface IProps {
  eventId: string;
}



const GuestList: React.FC<IProps> = ({ eventId }) => {

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseClick = () => {
    setModalOpen(false);
  }

  const handleAddClick = () => {
    setModalOpen(false);
  //  refetch();
  }

  return(
    <>
      <PanelContainer>
        <Panel>
          <PanelHeader style={{backgroundColor: '#80BAF9'}} className='fg-white'>
            <Grid>
            <Row>
              <Col xs={10}>
                <h3 style={{marginBottom: 25, marginLeft: 15}}>Event guest list</h3>
              </Col>
              <Col xs={2}>
                <Button style={{marginTop: 20}} onClick={() => setModalOpen(true)}>Add Guest</Button>
              </Col>
            </Row>
            </Grid>
          </PanelHeader>
          <PanelBody>
            <Grid>
              <Row>
                <Col sm={24}>
                  <GuestListTable eventId={eventId}/>
                </Col>
              </Row>
            </Grid>
          </PanelBody>
        </Panel>
      </PanelContainer>
      <CreateGuest
        eventId={eventId}
        isOpen={modalOpen}
        onCloseClick={ () => handleCloseClick()  }
        onAddClick={ () => handleAddClick() }
      />
    </>
  );
};

export default GuestList;

