import * as React from 'react';
import {
    Nav,
    NavItem,
    Icon,
} from '@mementovr/rubix-theme';

import { auth } from '../../../services/auth';

const HeaderNavigation: React.FC = () => {
  return (
    <ul className='navbar-content pull-right'>
      <Nav className='hidden-xs'>
        {/* <NavItem divider />
        <NavItem eventKey={1} href='#' className='hidden-sm'>
            <Icon glyph='icon-fontello-circle' className='fg-theme' style={{lineHeight: 1, fontSize: 24, top: 2, position: 'relative' }} />
        </NavItem> */}
        {/* <NavItem divider /> */}
        {/* <FlagMenu /> */}
        {/* <NavItem divider /> */}
        {/* <DirectNavItem glyph='user-female' eventKey={3} path={::this.getPath('social')} className='small-font' style={{position: 'relative', top: 2}} /> */}
        {/* <SettingsMenu /> */}
        {/* <NavItem divider /> */}
        {/* <DirectNavItem glyph='mail-3' eventKey={5} path={::this.getPath('mailbox/inbox')} /> */}
        {/* <NotificationsMenu /> */}
        {/* <RssMenu /> */}
      </Nav>
      <Nav>
        <NavItem className='logout' onClick={() => auth.logout()}>
          <Icon bundle='fontello' glyph='off-1' />
        </NavItem>
      </Nav>
    </ul>
  );
};

export default HeaderNavigation
