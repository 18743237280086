import React from 'react';
import { Button } from '@mementovr/rubix-theme';


interface IImageGalleryControlsProps {
  selectedCount: number;
  onMoveClick: any;
}

const ImageGalleryControls: React.FC<IImageGalleryControlsProps> = ({ selectedCount, onMoveClick }) => {

  const galleryControlsStyle = {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between'
  };

  const galleryControlsActionsStyle = {
    width: 200,
    display: 'flex',
    justifyContent: 'end'
  };

  const isMoveButtonDisabled = selectedCount > 0 ? false : true;

  return (
    <div style={galleryControlsStyle}>
      <div>
        <span>{ selectedCount } images selected</span>
      </div>

      <div style={galleryControlsActionsStyle}>
        <Button
          bsStyle="primary"
          disabled={isMoveButtonDisabled}
          onClick={onMoveClick}
        >
          Move selected
        </Button>
      </div>

    </div>
  );

};

export default ImageGalleryControls;
