import React, { useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { GET_EVENT_NAVIGATION_QUERY } from '../EventNavigation/EventNavigation';

import {
  Modal,
  Button,
  ListGroup,
  ListGroupItem
} from '@mementovr/rubix-theme';


interface IProps {
  id: string;
  location: string;
  isOpen: boolean;
  selectedImages: any;
  onCloseClick: any;
  onMoveClick: any;
}

const MOVE_IMAGES_MUTATION = gql`
  mutation MOVE_IMAGES_MUTATION(
    $imageableId: ID!
    $imageableType: ImageLocation!
    $images: [ID!]!
  ) {
    moveImages(
      input: {
        imageableType: $imageableType
        imageableId: $imageableId
        images: $images
      }
    ) {
    id
  }
}`;


const EventGalleryMove: React.FC<IProps> = ({ id, location, isOpen, selectedImages, onCloseClick, onMoveClick }) => {

  const [selectedPlace, setSelectedPlace] = useState({location: '', id: 0});
  const [moveEnabled, setMoveEnabled] = useState(false);

  const selectPlace = ( name: string, placeId: number) => {
    setSelectedPlace({ location: name, id: placeId});
    setMoveEnabled(true);
  }

  const { data, error, loading } = useQuery(
    GET_EVENT_NAVIGATION_QUERY, {
      variables: { id, location }
    }
    );

  if (loading) {
    return <div>Loading...</div>;
  };

  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const event = data.getEvent;

  const areaListItems = event && event.areas && event.areas.map((area:any, index:number) => {
    return (
      <div key={index}>
        <ListGroupItem
          onClick={() => selectPlace("AREA", area.id) }
        >Area: { area.name }</ListGroupItem>
        {
          area && area.scenes.map((scene:any, sceneIndex:number) => {
            return (
              <ListGroupItem key={"scene" + sceneIndex}
                onClick={() => selectPlace("SCENE", scene.id)}
              >
                Scene: {scene.name}
              </ListGroupItem>
            )
          })
        }
      </div>
    )
  });

  return (
    <Mutation
      mutation={MOVE_IMAGES_MUTATION}
      variables={{ imageableId: selectedPlace.id, imageableType: selectedPlace.location , images: selectedImages }}
      update={() => onMoveClick() }
    >
      { (mutate: any) => (
        <Modal bsSize="large" show={isOpen}>
          <Modal.Header>
            <Modal.Title>Select where to move images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              { areaListItems }
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle='danger' onClick={onCloseClick}>Close</Button>
            <Button disabled={!moveEnabled} bsStyle='primary' onClick={mutate} >Move selected</Button>
          </Modal.Footer>
        </Modal>
      )
    }
    </Mutation>
  );
};

export default EventGalleryMove;
